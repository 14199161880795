<template>
	<div id="ag-grid-demo">
		<vx-card id="grid-loader" class="vs-con-loading__container">
			<ag-grid-vue
				:gridOptions="gridOptions"
				class="ag-theme-material w-100 mb-4 ag-grid-table"
				:columnDefs="columnDefs"
				:defaultColDef="defaultColDef"
				:rowData="tableData"
				rowSelection="multiple"
				:enableRangeSelection="true"
				:suppressCopyRowsToClipboard="true"
				colResizeDefault="shift"
				:suppressDragLeaveHidesColumns="true"
				:animateRows="false"
				:floatingFilter="true"
				:pagination="true"
				:paginationPageSize="paginationPageSize"
				:suppressPaginationPanel="true"
				:suppressRowClickSelection="true"
				:rowClassRules="rowClassRules"
				:statusBar="statusBar"
				:sideBar="sideBar"
				:rowHeight="38"
				:getContextMenuItems="getContextMenuItems"
				@filter-changed="onFilterChanged"
				@column-resized="onColumnResized">
			</ag-grid-vue>
			<div class="vx-row">
				<div class="vx-col w-full">
					<vs-pagination
					:total="totalPages"
					:max="maxPageNumbers"
					v-model="currentPage" />
				</div>
			</div>
		</vx-card>
	</div>
</template>

<script>
	import { AgGridVue } from "ag-grid-vue"
	import "ag-grid-enterprise";

	import '@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss'

	export default {
		components: {
			AgGridVue,
		},
		data() {
			return {
				searchQuery: '',
				gridOptions: {},
				maxPageNumbers: 7,
				gridApi: null,
				defaultColDef: {
					sortable: true,
					resizable: true,
					suppressMenu: true,
					suppressMovable: false,
					filter: false,
					filterParams: {
						suppressAndOrCondition: true,
					},
				},
				rowClassRules: null,
				statusBar: null,
				sideBar: null,
				columnDefs: [
				{
					headerName: 'Domain',
					field: 'domain',
					minWidth: 275,
					filter: "agTextColumnFilter",
					pinned: 'left',
					cellRenderer: (data) => {
						if(data.value) {
							return `<a href="/search?domain_name=${encodeURIComponent(data.value)}" target="_blank">${data.value}</a>`
						}
						else {
							return '';
						}
					},
				},
				{
					headerName: 'ID',
					field: 'id',
					filter: "agTextColumnFilter",
					minWidth: 150,
					hide: true,
				},
				{
					headerName: 'Email',
					field: 'email',
					filter: "agTextColumnFilter",
					minWidth: 275,
				},
				{
					headerName: 'Name',
					field: 'name',
					filter: "agTextColumnFilter",
					minWidth: 200,
				},
				{
					headerName: 'Abuse Type',
					field: 'abuse_type',
					filter: "agTextColumnFilter",
					minWidth: 275,
				},
				{
					headerName: 'Message',
					field: 'message',
					filter: "agTextColumnFilter",
					minWidth: 400,
				},
				{
					headerName: 'Ticket ID',
					field: 'ticket_id',
					filter: "agTextColumnFilter",
					minWidth: 150,
					cellRenderer: (data) => {
						if(data.value) {
							return `<a href="https://radixhelp.freshdesk.com/a/tickets/${data.value}" target="_blank">${data.value}</a>`
						}
						else {
							return '';
						}
					},
				},
				{
					headerName: 'Created At',
					field: 'created_at',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
									comparator: function(filterLocalDateAtMidnight, cellValue) {
										if (cellValue === null) return -1;

										let cellDate = new Date(cellValue);
										
										let cellDateString = new Date(cellDate.toDateString());
										let filterLocalDateAtMidnightString = new Date(filterLocalDateAtMidnight.toDateString());
										
										if (cellDateString.getTime() === filterLocalDateAtMidnightString.getTime()) {
											return 0;
										}
										if (cellDate < filterLocalDateAtMidnight) {
											return -1;
										}
										if (cellDate > filterLocalDateAtMidnight) {
											return 1;
										}
									},
								}
							},
						],
					},
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					},
					sort: 'desc',
				},
				{
					headerName: 'Updated At',
					field: 'updated_at',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
									comparator: function(filterLocalDateAtMidnight, cellValue) {
										if (cellValue === null) return -1;

										let cellDate = new Date(cellValue);
										
										let cellDateString = new Date(cellDate.toDateString());
										let filterLocalDateAtMidnightString = new Date(filterLocalDateAtMidnight.toDateString());
										
										if (cellDateString.getTime() === filterLocalDateAtMidnightString.getTime()) {
											return 0;
										}
										if (cellDate < filterLocalDateAtMidnight) {
											return -1;
										}
										if (cellDate > filterLocalDateAtMidnight) {
											return 1;
										}
									},
								}
							},
						],
					},
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					}
				},
				],
				tableData: null,
			}
		},
		watch: {
			windowWidth(val) {
				// Toggle colummn movable
				this.toggleColumnMovable(val);
				// Toggle colummn pinning
				this.toggleColumnPinned(val);
			},
		},
		computed: {
			windowWidth: function(){
				return this.$store.state.windowWidth;
			},
			paginationPageSize() {
				if(this.gridApi) {
					return this.gridApi.paginationGetPageSize()
				}
				else {
					return 500
				}
			},
			totalPages() {
				if(this.gridApi) {
					return this.gridApi.paginationGetTotalPages()
				}
				else {
					return 0
				}
			},
			currentPage: {
				get() {
					if(this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
						else return 1
					},
				set(val) {
					this.gridApi.paginationGoToPage(val - 1);
				}
			},
			user_role() {
				return JSON.parse(localStorage.getItem('userDetails')).role;
			},
		},
		methods: {
			showDivLoading(){
				this.$vs.loading({
					container: '#grid-loader',
					scale: 0.45
				});
			},
			hideDivLoading(){
				this.$vs.loading.close("#grid-loader > .con-vs-loading");
			},
			toggleColumnMovable(val) {
				if(val <= 576) {
					this.defaultColDef['suppressMovable'] = true;
				}
				else {
					this.defaultColDef['suppressMovable'] = false;
				}
			},
			toggleColumnPinned(val) {
				if(val <= 576) {
					this.maxPageNumbers = 4;
					this.gridOptions.columnApi.setColumnPinned('domain', null);
				}
				else {
					this.gridOptions.columnApi.setColumnPinned('domain', 'left')
				}
			},
			onColumnResized() {
				this.gridApi.resetRowHeights();
			},
			updateSearchQuery(val) {
				this.gridApi.setQuickFilter(val);
			},
			sizeColumnsToFit() {
				this.gridApi.sizeColumnsToFit();
			},
			onFilterChanged() {
				// Set Listing Filtered Count
				this.$store.dispatch("setListingFilteredCount", this.gridApi.getDisplayedRowCount());
			},
			populateTableData(loader) {
				let self = this;

				this.$axiosSecure.get('/get-suspension-requests')
				.then((response) => {
					this.tableData = response.data;

					// Set Listing Count
					this.$store.dispatch("setListingTotalCount", response.data.length);
					// Set Listing Filtered Count
					this.$store.dispatch("setListingFilteredCount", response.data.length);
				
					if(loader == 'loader') {
						// Hide loader
						this.hideDivLoading();
					}
				})
				.catch((error) => {
					// Error notification
					self.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			},
			getContextMenuItems(params) {
				let result,
					self = this;

				if(params['column'] && params['column']['colId'] && params['column']['colId'] == 'domain') {
					result = [
						{
							name: "Resolve Domain(s)",
							action: function() {
								let rangeSelection = self.gridApi.getCellRanges(),
									value = params['value'];

								if(rangeSelection) {
									// Get selected cells & iterate through them to find domains
									rangeSelection.forEach(item => {
										let start = Math.min(item.startRow.rowIndex, item.endRow.rowIndex),
											end = Math.max(item.startRow.rowIndex, item.endRow.rowIndex),
											isRightClicked = false;
										
										for (let i = start; i <= end; i++) {
											if(self.gridApi.getDisplayedRowAtIndex(i)['data']['domain'] == value) {
												isRightClicked = true
											}
											window.open('https://anonymto.com/?http://' + self.gridApi.getDisplayedRowAtIndex(i)['data']['domain'], '_blank');
										}
										
										if(!isRightClicked) {
											window.open('https://anonymto.com/?http://' + value);
										}
									});
								}
								else {
									window.open('https://anonymto.com/?http://' + value);
								}
							},
						},
						'separator',
						'copy',
						'copyWithHeaders',
						'paste',
						'separator',
						'export'
					];
				}
				else {
					result = [
						'copy',
						'copyWithHeaders',
						'paste',
						'separator',
						'export'
					];
				}

				return result;
			}
		},
		beforeMount() {
			// Table Data AXIOS Call
			this.populateTableData();

			// Reset movable columns on mobile
			this.toggleColumnMovable(this.windowWidth);

			// Set Grid sidebar
			this.sideBar = {
				toolPanels: [
				{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel",
					toolPanelParams: {
						suppressRowGroups: true,
						suppressValues: true,
						suppressPivots: true,
						suppressPivotMode: true,
						suppressSideButtons: true,
						suppressColumnSelectAll: true,
						suppressColumnExpandAll: true
					}
				}
				],
			};
		},
		mounted() {
			this.gridApi = this.gridOptions.api;

			// Resize to fit columns
			this.sizeColumnsToFit();

			// Reset pinned columns on mobile
			this.toggleColumnPinned(this.windowWidth);

			// Page Refresh Event
			this.$root.$on('refreshPage', () => {
				// Reset filters
				this.gridApi.setFilterModel(null);
				// Reset selection
				this.gridApi.deselectAll();
				// Reset sort
				this.gridApi.setSortModel([
					{
					colId: "created_at",
					sort: "desc"
					}
				]);
				// Table Data AXIOS Call
				this.populateTableData();
			});

			// Page Download Event
			this.$root.$on('downloadPage', () => {
				// AgGrid CSV Export
				this.gridApi.exportDataAsCsv()
			});
		},
		destroyed() {
			// Clear Listing Count
			this.$store.dispatch("clearListingCount");
			
			// Unbind Button Event Listeners
			this.$root.$off('refreshPage');
			this.$root.$off('downloadPage');
		}
	}

</script>